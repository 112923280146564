import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-40520622")
const _hoisted_1 = { class: "popular-casino d-flex flex-column center" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "title" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, { to: _ctx.href }, {
      default: _withCtx(() => [
        (_ctx.thumbnail)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "category-thumbnail",
              src: _ctx.$getFileSource(_ctx.thumbnail.url),
              alt: _ctx.title
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["to"]),
    _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.title), 1)
  ]))
}