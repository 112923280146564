import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-b3bd4ac6")
const _hoisted_1 = { class: "center" }
const _hoisted_2 = { class: "center" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_a_input_search = _resolveComponent("a-input-search")!

  return (_openBlock(), _createElementBlock("div", {
    class: "search-casinos center",
    style: _normalizeStyle({
      'background-image':
        'url(' +
        require('../../assets/images/demosimages/Main-Banner.jpeg') +
        ')',
    })
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SectionHeading, {
          title: _ctx.title,
          subtitle: _ctx.subtitle,
          description: _ctx.description
        }, null, 8, ["title", "subtitle", "description"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createVNode(_component_a_input_search, {
            class: "search-casino",
            value: _ctx.value,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.value = $event)),
            placeholder: "Search for casinos",
            "enter-button": "Search",
            size: "large"
          }, null, 8, ["value"])
        ], {}, true)
      ])
    ])
  ], 4))
}