import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3440cfe5")
const _hoisted_1 = { class: "popular-game-card d-flex" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "title" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "d-flex flex-column align-items-start",
      to: _ctx.href
    }, {
      default: _withCtx(() => [
        (_ctx.thumbnail)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "popular-games-thumbnail",
              src: _ctx.$getFileSource(_ctx.thumbnail.url)
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}