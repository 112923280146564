import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopularGameCard = _resolveComponent("PopularGameCard")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, {
    slidesPerView: 3,
    grid: {
      rows: 2,
      fill: 'row',
    },
    spaceBetween: 35,
    pagination: {
      clickable: true,
    },
    class: "mySwiper"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_swiper_slide, {
          key: item,
          class: "swiper-slide"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PopularGameCard, {
              title: item.title,
              thumbnail: item.thumbnail
            }, null, 8, ["title", "thumbnail"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}