import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5c81c57c")
const _hoisted_1 = {
  class: "container-casinos mx-0",
  style: {"margin-top":"85px"}
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "container container-characteristics hide-on-mobile" }
const _hoisted_5 = { class: "d-flex characterList" }
const _hoisted_6 = { class: "wrapper-deals" }
const _hoisted_7 = { class: "container container-deals" }
const _hoisted_8 = { class: "wrapper-categories hide-on-mobile" }
const _hoisted_9 = { class: "container container-categories" }
const _hoisted_10 = { class: "d-flex popularCategories justify-content-between" }
const _hoisted_11 = { class: "wrapper-popgames" }
const _hoisted_12 = { class: "container container-popgames" }
const _hoisted_13 = { class: "container-news" }
const _hoisted_14 = { class: "container" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchCasinosSection = _resolveComponent("SearchCasinosSection")!
  const _component_WizardBar = _resolveComponent("WizardBar")!
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_CardCasinoOffer = _resolveComponent("CardCasinoOffer")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_CharacteristicsEsports = _resolveComponent("CharacteristicsEsports")!
  const _component_BestDealsCards = _resolveComponent("BestDealsCards")!
  const _component_PopularCategoriesCards = _resolveComponent("PopularCategoriesCards")!
  const _component_PopularGamesSlider = _resolveComponent("PopularGamesSlider")!
  const _component_CardNews = _resolveComponent("CardNews")!
  const _component_a_input_search = _resolveComponent("a-input-search")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SearchCasinosSection, {
      title: "Find Verified Offers From Trusted Casinos",
      description: "We have reviewed 2000+ deals to make your casino gaming experience better",
      subtitle: ""
    }),
    _createVNode(_component_WizardBar, { payoutTimeout: 15 }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_SectionHeading, {
          class: "subtitle text-start",
          title: "Best Online Casinos 2021",
          subtitle: "",
          description: ""
        }),
        (!_ctx.casinosLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_a_row, {
                justify: "center",
                type: "flex",
                gutter: [30, 30]
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.casinos.slice(0, 4), (item) => {
                    return (_openBlock(), _createBlock(_component_a_col, {
                      xl: 6,
                      xs: 24,
                      key: item
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CardCasinoOffer, {
                          class: "card-casino",
                          thumbnail: item.thumbnail,
                          title: item.title,
                          description: item.description,
                          rating: 
                  item.review === null ? 0 : _ctx.fCalcRating(item.review.rating)
                ,
                          href: {
                  name: 'Casino',
                  params: {
                    slug: item.slug,
                  },
                },
                          offer: item.termsAndConditions,
                          reward: item.excerpt
                        }, null, 8, ["thumbnail", "title", "description", "rating", "href", "offer", "reward"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_SectionHeading, {
        class: "subtitle text-start",
        title: "Why Coinflip Has Your Back",
        subtitle: "",
        description: ""
      }),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_CharacteristicsEsports, {
          thumbnail: { url: 'images/misc/Box.jpg' },
          title: "Explore Site Worldwide",
          subtitle: "We rate the casino by testing more practically & see how it run"
        }, null, 8, ["thumbnail"]),
        _createVNode(_component_CharacteristicsEsports, {
          thumbnail: { url: 'images/misc/Box3-1.png' },
          title: "Explore Site Worldwide",
          subtitle: "We listen to word of mouth spread gamblers across prairie"
        }, null, 8, ["thumbnail"]),
        _createVNode(_component_CharacteristicsEsports, {
          thumbnail: { url: 'images/misc/Box4.png' },
          title: "Explore Site Worldwide",
          subtitle: "We rate the casino by testing more practically & see how it run"
        }, null, 8, ["thumbnail"]),
        _createVNode(_component_CharacteristicsEsports, {
          thumbnail: { url: 'images/misc/Box3.png' },
          title: "Explore Site Worldwide",
          subtitle: "We rate the casino by testing more practically & see how it run"
        }, null, 8, ["thumbnail"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_SectionHeading, {
          class: "subtitle text-start",
          title: "Best Deals Available",
          subtitle: "",
          description: ""
        }),
        _createVNode(_component_a_row, { gutter: [32, 32] }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              xl: 12,
              xs: 24
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BestDealsCards, {
                  title: "Claim Special Reward From Wagering.",
                  subtitle: "",
                  href: "'https://modeltheme.com/go/coinflip/'",
                  thumbnail: { url: 'images/bonus-images/bonus_image_1.png' }
                }, null, 8, ["thumbnail"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              xl: 12,
              xs: 24
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BestDealsCards, {
                  title: "Win Real Money Prizes From £100 to £250",
                  subtitle: "",
                  href: "'https://modeltheme.com/go/coinflip/'",
                  thumbnail: { url: 'images/bonus-images/bonus_image_2.png' }
                }, null, 8, ["thumbnail"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              xl: 12,
              xs: 24
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BestDealsCards, {
                  title: "Sign-Up and Claim 50 Free Spin for Top",
                  subtitle: "",
                  href: "'https://modeltheme.com/go/coinflip/'",
                  thumbnail: { url: 'images/bonus-images/bonus_image_3.png' }
                }, null, 8, ["thumbnail"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              xl: 12,
              xs: 24
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BestDealsCards, {
                  title: "Sign-Up and Claim 50 Free Spin for Top",
                  subtitle: "",
                  href: "'https://modeltheme.com/go/coinflip/'",
                  thumbnail: { url: 'images/bonus-images/bonus_image_4.png' }
                }, null, 8, ["thumbnail"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              xl: 12,
              xs: 24
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BestDealsCards, {
                  title: "Win Real Money Prizes From £100 to £250",
                  subtitle: "",
                  href: "'https://modeltheme.com/go/coinflip/'",
                  thumbnail: { url: 'images/bonus-images/bonus_image_5.png' }
                }, null, 8, ["thumbnail"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              xl: 12,
              xs: 24
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BestDealsCards, {
                  title: "Join the Weekly 9-Ball Fun Bingo Room Piece",
                  subtitle: "",
                  href: "'https://modeltheme.com/go/coinflip/'",
                  thumbnail: { url: 'images/bonus-images/bonus_image_6.png' }
                }, null, 8, ["thumbnail"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              xl: 12,
              xs: 24
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BestDealsCards, {
                  title: "Instant Withdrawal at Spin & Wins",
                  subtitle: "",
                  href: "'https://modeltheme.com/go/coinflip/'",
                  thumbnail: { url: 'images/bonus-images/bonus_image_7.png' }
                }, null, 8, ["thumbnail"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              xl: 12,
              xs: 24
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BestDealsCards, {
                  title: "Claim Special Reward From Your Inbox Twice.",
                  subtitle: "",
                  href: "'https://modeltheme.com/go/coinflip/'",
                  thumbnail: { url: 'images/bonus-images/bonus_image_1.png' }
                }, null, 8, ["thumbnail"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_SectionHeading, {
          class: "subtitle text-start",
          title: "Popular Categories",
          subtitle: "",
          description: ""
        }),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_PopularCategoriesCards, {
            thumbnail: { url: 'images/category-icons/category-icon.png' },
            title: "Explore Site Worldwide",
            subtitle: "We rate the casino by testing more practically & see how it run"
          }, null, 8, ["thumbnail"]),
          _createVNode(_component_PopularCategoriesCards, {
            thumbnail: {
              url: 'images/category-icons/casino-category-1-1280x1280.png',
            },
            title: "Explore Site Worldwide",
            subtitle: "We listen to word of mouth spread gamblers across prairie"
          }, null, 8, ["thumbnail"]),
          _createVNode(_component_PopularCategoriesCards, {
            thumbnail: {
              url: 'images/category-icons/casino-category-3-1280x1280.png',
            },
            title: "Explore Site Worldwide",
            subtitle: "We rate the casino by testing more practically & see how it run"
          }, null, 8, ["thumbnail"]),
          _createVNode(_component_PopularCategoriesCards, {
            thumbnail: {
              url: 'images/category-icons/casino-category-4-1280x1280.png',
            },
            title: "Explore Site Worldwide",
            subtitle: "We rate the casino by testing more practically & see how it run"
          }, null, 8, ["thumbnail"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_SectionHeading, {
          class: "subtitle text-start",
          title: "Most Popular Games",
          subtitle: "",
          description: ""
        }),
        _createVNode(_component_PopularGamesSlider, { items: _ctx.popularGames }, null, 8, ["items"])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_SectionHeading, {
          class: "subtitle text-start",
          title: "Most Readable Blog",
          subtitle: "",
          description: ""
        }),
        (!_ctx.loadingNews)
          ? (_openBlock(), _createBlock(_component_a_row, {
              key: 0,
              justify: "center",
              type: "flex",
              gutter: [25, 25]
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.latestNews, (item) => {
                  return (_openBlock(), _createBlock(_component_a_col, {
                    xl: 8,
                    xs: 24,
                    key: item
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CardNews, {
                        class: "card-news mb-1 mb-md-0",
                        title: item.title,
                        thumbnail: item.thumbnail,
                        description: item.description,
                        author: {
                name: 'Trader Marcus',
                href: 'https://modeltheme.com/go/coinflip/',
              },
                        href: {
                name: 'Blog',
                params: {
                  slug: item.slug,
                },
              }
                      }, null, 8, ["title", "thumbnail", "description", "author", "href"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_SearchCasinosSection, {
      title: "Want More Legit Offer Provided By Licensed Casino?",
      description: "Leave your email to receive unique offers and updates",
      subtitle: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_input_search, {
          class: "search-casino mt-4",
          placeholder: "Email Address",
          "enter-button": "Subscribe",
          size: "large"
        })
      ]),
      _: 1
    })
  ]))
}