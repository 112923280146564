
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
// import Swiper core and required modules (ignore vs code "Grid" error)
import SwiperCore, { Pagination, Grid } from "swiper";
import PopularGameCard from "../PopularGameCard/PopularGameCard.vue";
// import Grid from "swiper";
import { defineComponent, PropType } from "vue";
// install Swiper modules
SwiperCore.use([Grid, Pagination]);
import { PopularGameCard as CardsType } from "../PopularGameCard/PopularGameCard";

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    PopularGameCard,
  },
  props: {
    items: {
      type: Object as PropType<CardsType[]>,
    },
  },
});
