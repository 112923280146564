
import { defineComponent } from "vue";
import SectionHeading from "../SectionHeading/SectionHeading.vue";
export default defineComponent({
  components: { SectionHeading },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: "",
    };
  },
});
