
import { defineComponent, Ref } from "vue";
import CardNews from "../components/CardNews/CardNews.vue";
import fetchLatestNews from "../misc/composition/fetchLatestNews";
import SectionHeading from "../components/SectionHeading/SectionHeading.vue";
import SearchCasinosSection from "@/components/SearchCasinosSection/SearchCasinosSection.vue";
import WizardBar from "@/components/WizardBar/WizardBar.vue";
import CharacteristicsEsports from "@/components/CharacteristicsEsports/CharacteristicsEsports.vue";
import fetchHomeCasinoByID from "../misc/composition/fetchHomeCasinoByID";
import casinoHelpers from "../misc/composition/casinoHelpers";
import CardCasinoOffer from "../components/CardCasinoOffer/CardCasinoOffer.vue";
import { Casino } from "@/types";
import BestDealsCards from "@/components/BestDealsCards/BestDealsCards.vue";
import PopularCategoriesCards from "@/components/PopularCategoriesCards/PopularCategoriesCards.vue";
import PopularGamesSlider from "@/components/PopularGamesSlider/PopularGamesSlider.vue";

export default defineComponent({
  components: {
    CardNews,
    SectionHeading,
    SearchCasinosSection,
    WizardBar,
    CharacteristicsEsports,
    CardCasinoOffer,
    BestDealsCards,
    PopularCategoriesCards,
    PopularGamesSlider,
  },
  setup() {
    const { latestNews, loading: loadingNews } = fetchLatestNews();
    const { casinos, loading: casinosLoading } = fetchHomeCasinoByID();
    const { fCalcRating } = casinoHelpers({} as Ref<Casino>);
    document.title = "Casino Affiliate - Coinflip";

    return {
      fCalcRating,
      casinos,
      casinosLoading,
      latestNews,
      loadingNews,
    };
  },
  data() {
    return {
      popularGames: [
        {
          title: "Soccer Games",
          thumbnail: { url: "images/popular-games/Book-of-Deluxe-505x305.jpg" },
          link: "https://modeltheme.com/go/coinflip/",
        },
        {
          title: "Narcos",
          thumbnail: { url: "images/demosimages/PopularGameCard-2.jpeg" },
          link: "https://modeltheme.com/go/coinflip/",
        },
        {
          title: "Narcos",
          thumbnail: { url: "images/popular-games/deadwood-505x305.jpg" },
          link: "https://modeltheme.com/go/coinflip/",
        },
        {
          title: "Narcos",
          thumbnail: { url: "images/popular-games/gonzos-quest-505x305.jpg" },
          link: "https://modeltheme.com/go/coinflip/",
        },
        {
          title: "Narcos",
          thumbnail: { url: "images/popular-games/minecraft-505x305.jpg" },
          link: "https://modeltheme.com/go/coinflip/",
        },
        {
          title: "Narcos",
          thumbnail: { url: "images/popular-games/tarzan-jungle-505x305.jpg" },
          link: "https://modeltheme.com/go/coinflip/",
        },
        {
          title: "Narcos",
          thumbnail: { url: "images/popular-games/viking-game-505x305.jpg" },
          link: "https://modeltheme.com/go/coinflip/",
        },
        {
          title: "Narcos",
          thumbnail: { url: "images/demosimages/PopularGameCard-2.jpeg" },
          link: "https://modeltheme.com/go/coinflip/",
        },
      ],
    };
  },
});
